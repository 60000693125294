.bg_busca_interna{
	background-color: white;
}

.busca-filtros{
	display: block;
	width: 100%;
	background-color: white;
	padding: 1rem;
	border: 1px solid $gray1;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}

.menuacao_btn{
	background-color: $colorTerciary !important;
	border: solid 1px white !important;
	color: white !important;
}

#containerDaBusca {
	width: 100%;
	display: block;
	background-color: $colorPrimary;
}

.busca {
	background-color: $colorPrimary;
	display: block;
	width: 60%;
	padding: 1rem 2rem;
	text-align: center;
	border-radius: .2rem;

	& .frase_busca {
		display: block;
		width: 100%;

		& h1 {
			color: $colorTerciary;
			font-size: 1.7rem;
			font-weight: 600;
		}
	}

	& .btns_tipo_busca{
		display: block;
		width: 100%;
		& .botoes{
			display: block;
			width: 50%;

			& button{
				background-color: white;
				border: solid 1px $gray4;
				border-radius: 2rem;
				font-size: 1.4rem;
				color: $colorTerciary;
				font-weight: 600;
				padding: .5rem 1.5rem;
				margin: 0 .5rem;
				cursor: pointer;
				transition: all .15s;
			}
		}
	}

	& .busca-filtro{
		width: 100%;
		display: block;

		& .bloco_busca{
			padding: 0;
			margin: 0;
			text-align: center;
			display: block;
			width: 100%;

			& .filtros_busca_home{
				width: 30%;
				display: inline-block;
				padding: 1rem 0;
				& p{
					font-weight: 600;
					color: $colorTerciary;
					font-size: .9rem;
					font-weight: 400;
					margin: 0;
				}
			}

			& .filtros_busca_home2{
				width: 30%;
				display: inline-block;
				padding: 1rem 0;
				& p{
					font-weight: 600;
					color: $colorTerciary;
					font-size: .9rem;
					font-weight: 400;
					margin: 0;
				}
			}

			& .filtros_busca_home_input{
				width: 20%;
				display: inline-block;
				padding: 1rem 0;
				& p{
					font-weight: 600;
					color: $colorTerciary;
					font-size: .9rem;
					font-weight: 400;
					margin: 0;
				}
			}

			& .btn-home{
				padding: .8rem .5rem;
				border-radius: .1rem;
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				background-color: $colorTerciary;
				color: white;
				font-size: 1rem;
				font-weight: 600;
				border: solid 1px white;
				position: relative;
				transition: all .3s ease-in-out;
				cursor: pointer;
				overflow: hidden;
				z-index: 0;

				&::before{
					content: "";
					position: absolute;
					top: -50px;
					right: -30px;
					width: 2rem;
					height: 2rem;
					background-color: darken($colorTerciary, 5);
					border-radius: 50%;
					transition: all .35s ease-in-out;
					z-index: -1;
				}

				&:hover{
					color: white;
					&:before{
						transform: scale(42);
					}
				}
			}
		}
	}
}

.pd_btns_busca_mobile{
	padding: 0 .1rem;
}

.btn_mostrar_mais_busca_interna{
	text-align: center;
	width: 10%;
	background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	color: white;
	font-size: 1rem;
	font-weight: 600;
	border-radius: 2rem;
	border: solid 2px white;
	padding: .5rem .8rem;
	box-shadow: 5px 7px 15px rgba(black, .3);
	cursor: pointer;
	transition: all .25s ease-out;
	&:hover{
		background: linear-gradient(to bottom right, $colorSecondary, $colorPrimary);
	}
}

.botao_buscar_int{
	width: 100%;
	display: block;
	text-align: center;
	border: solid 1px $colorPrimary;
	color: $colorPrimary;
	font-size: .9rem;
	font-weight: 600;
	background-color: white;
	padding: .75rem 1rem;
	border-radius: .1rem;
	position: relative;
	transition: all .25s ease-in-out;
	cursor: pointer;
	overflow: hidden;
	z-index: 0;
	margin-top: 2px;


	&::before{
		content: "";
		position: absolute;
		top: -50px;
		right: -30px;
		width: 2rem;
		height: 2rem;
		background-color: $colorPrimary;
		border-radius: 50%;
		transition: all .35s ease-in-out;
		z-index: -1;
	}

	&:hover{
		color: white;
		&:before{
			transform: scale(13);
		}
	}
}

.botao_limpar_int{
	width: 100%;
	display: block;
	text-align: center;
	border: solid 1px $colorTerciary;
	color: $colorTerciary;
	font-size: .9rem;
	font-weight: 600;
	background-color: white;
	padding: .75rem 1rem;
	border-radius: .1rem;
	position: relative;
	transition: all .25s ease-in-out;
	cursor: pointer;
	overflow: hidden;
	z-index: 0;
	margin-top: 2px;


	&::before{
		content: "";
		position: absolute;
		top: -50px;
		right: -30px;
		width: 2rem;
		height: 2rem;
		background-color: $colorTerciary;
		border-radius: 50%;
		transition: all .35s ease-in-out;
		z-index: -1;
	}

	&:hover{
		color: white;
		&:before{
			transform: scale(13);
		}
	}
}

.botao_primary{
	width: fit-content;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	border: solid 1px $colorPrimary;
	color: $colorPrimary;
	font-size: .9rem;
	font-weight: 600;
	background-color: white;
	padding: .7rem 1rem;
	border-radius: .1rem;
	position: relative;
	transition: all .25s ease-in-out;
	cursor: pointer;
	overflow: hidden;
	z-index: 0;
	margin-top: 2px;
	margin-right: 1rem;


	&::before{
		content: "";
		position: absolute;
		top: -50px;
		right: -30px;
		width: 2rem;
		height: 2rem;
		background-color: $colorPrimary;
		border-radius: 50%;
		transition: all .35s ease-in-out;
		z-index: -1;
	}

	&:hover{
		color: white;
		&:before{
			transform: scale(15);
		}
	}
}

.botao_secondary{
	width: fit-content;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	border: solid 1px $colorTerciary;
	color: $colorTerciary;
	font-size: .9rem;
	font-weight: 600;
	background-color: white;
	padding: .75rem 1rem;
	border-radius: .1rem;
	position: relative;
	transition: all .25s ease-in-out;
	cursor: pointer;
	overflow: hidden;
	z-index: 0;
	margin-top: 2px;


	&::before{
		content: "";
		position: absolute;
		top: -50px;
		right: -30px;
		width: 2rem;
		height: 2rem;
		background-color: $colorTerciary;
		border-radius: 50%;
		transition: all .35s ease-in-out;
		z-index: -1;
	}

	&:hover{
		color: white;
		&:before{
			transform: scale(13);
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	#containerDaBusca {
		width: 75%;
		display: block;
	}

	.busca {
		& .frase_busca {
			& h1 {
				font-size: 1.4rem;
			}
		}
	}
}

@media all and (max-width: 1023px){
	#containerDaBusca {
		width: 100%;
		display: block;
	}

	.busca {
		display: block;
		width: 100%;
		padding: 1rem;
		text-align: center;
		border: solid 1px $gray4;
		box-shadow: 0 0 10px 1px rgba(black, .1);
		border-radius: .2rem;

		& .frase_busca {
			& h1 {
				font-size: 1.4rem;
				line-height: 2rem;
			}
		}

		& .btns_tipo_busca{
			display: block;
			width: 100%;
			
			& .botoes{
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1rem;
				padding: 0;
				
				& button{
					background-color: white;
					border: solid 1px rgba($colorTerciary, .5);
					border-radius: 2rem;
					font-size: 1.2rem;
					color: $colorTerciary;
					font-weight: 600;
					padding: .5rem 0;
					margin: 0;
					cursor: pointer;
					transition: all .15s ease-in-out;
					width: 100%;
				}
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				
				& .filtros_busca_home{
					width: 100%;
					display: inline-block;
					padding: 0 0 1rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home2{
					width: 100%;
					display: inline-block;
					padding: 0 0 1rem 0 !important;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home_input{
					width: 50%;
					display: inline-block;
					padding: 0;
				}
				& .btn-home{
					padding: .9rem .5rem;
				}
			}
		}
	}
	
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		padding-bottom: 7rem;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
}
.heigt{
	height: 2.3rem !important;
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}
