.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: 4px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 14px !important;
		color: $gray3;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: white;
		color: $gray2 !important;
		font-size: .9rem !important;
		font-weight: 400 !important;
		border: solid 1px $gray4;
		border-radius: .1rem;
		padding: .2rem 1rem;
		text-align: left;
	}
	& .component {
		border-top: none;
		border-bottom: 1px solid $gray4 !important;
		border-left: 1px solid $gray4 !important;
		border-right: 1px solid $gray4 !important;
		z-index: 10;
		& .items{
			overflow: auto;
			& .group{
				color: $gray2 !important;
			}
			
			& .group_itens{
			}
		}


		& .botoes {
			& .undo{
				background-color: $colorTerciary;
				border-right: solid 1px white;
				color: white !important;
				font-weight: 900;
				cursor: pointer;
				&:hover{
					background-color: darken($colorTerciary,5);
				}

			}
			& .ok{
				background-color: $colorPrimary;
				color: white !important;
				font-weight: 900;
				cursor: pointer;
				&:hover{
					background-color: darken($colorPrimary,5);
				}
			}

			& button {
				display: block;
				margin: 0;
				outline: none;
				border: 0;
				background-color: transparent;
				height: inherit;
			}
		}
	}
}