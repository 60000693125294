.imo_telefones_btn{
    position: fixed !important;
	bottom: 1.5rem !important;
	right: 1.5rem !important;
	z-index: 999;
       
    & #whatsapp_lista_telefones{
        padding: .5rem;
        position: fixed;
        bottom: 1.5rem;
        right: 8rem;
        
        & .corretor_btn_wpp{
            display: block;
            width: 280px;
            background-color: white;
            padding: .5rem 1rem;
            border: solid 1px #25d366;
            box-shadow: 0 0 10px 1px rgba(black, .1);
            border-radius: .5rem;
            margin-bottom: 1rem;

            & .corretor_img{
                width: 30%;
                display: inline-block;
                vertical-align: middle;

                & .img{
                    width: calc(350px / 5.5);
                    height: calc(350px / 5.5);
                    margin: 0%;
                    display: block;
                    position: relative;
                    overflow: hidden;
                    border-radius: 50%;

                    & img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: calc(350px / 5.5);
                        height: calc(350px / 5.5);
                        object-fit: cover;
                        -webkit-user-drag: none;
                    }
                }
            }
        
            & .corretor_dados{
                width: 70%;
                display: inline-block;
                vertical-align: middle;
                padding: 0;
                margin: 0;

                & .nome{
                    margin: .2rem 0;
                    font-size: .9rem;
                    font-weight: 600;
                    color: $gray2;
                }
        
                & .btn_zap{
                    display: block;
                    width: 100%;
                    margin-left: 0;
                    background-color: #25d366;
                    border-radius: 2rem;
                    transition: all .2s;
                    & p{
                        text-align: center;
                        color: white;
                        font-weight: 600;
                        font-size: .9rem;
                        padding: .4rem 0;
                        user-select: none;
                        cursor: pointer;

                        & i{
                            font-size: 1.2rem;
                            font-weight: 400;
                            color: white;
                            vertical-align: middle;
                        }
                    }
        
                    &:hover{
                        background-color: darken(#25d366, 5);
                    }
                }
            }
        }
        
    }

    & #wpp_pulsante{
        width: 100%;
		display: block;
		background-color: #25d366;
		margin: auto;
        width: 4.5rem;
        height: 4.5rem;
		border-radius: 50%;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
        position: relative;
        cursor: pointer;

        & i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 2.2rem;
            font-weight: 500;
        }
    }
}

.pai_section_dialog{
    position: fixed !important;
	bottom: 2.5rem !important;
	right: 8rem !important;
	z-index: 9997;
    
    & .section_dialog{
        display: block;
        width: 100%;
        padding: 1rem 1rem 2rem 1rem;
        border-radius: .5rem;
        background-color: white;
        position: relative;
        border: solid 2px $gray4;
        box-shadow: 0 0 20px 2px rgba(black, .2);
        & .btn_fechar_form_zap{
            position: absolute;
            top: -1rem;
            right: -1rem;
            background-color: #25d366;
            border-radius: 50%;
            padding: .5rem;
            border: solid 3px white;
            transition: all .15s;
            height: 2.5rem;
            width: 2.5rem;
            cursor: pointer;
            & i{
                color: white;
                text-align: center;
                padding: 0 0 0 3px;
            }
            &:hover{
                background-color: white;
                border: solid 3px #25d366;
                & i{
                    color: #25d366;
                }
            }
        }

        & .dialog_wpp{
            display: block;
            width: 100%;
            padding: 0 2rem;

            & h2{
                text-align: center;
                font-size: 1.5rem;
                font-weight: 500;
                color: $gray1;
            }

            & .under_p{
                text-align: center;
                width: 4rem;
                height: 2px;
                margin: auto;
                background-color: #25d366;
            }

            & .inputs_wpp{
                display: block;
                width: 100%;

                & .inputs_dados{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    padding: 0 1rem 0 0;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: 1rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & input{
                        border: solid 1px $gray4;
                        border-radius: .2rem;
                        width: 100%;
                        display: block;
                        padding: .7rem 1rem;
                    }
                }

                & .input_msg{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    padding: 0 0 0 1rem;
                    
                    & p{
                        margin: 0;
                        line-height: 1.5rem;
                        font-size: 1rem;
                        font-weight: 400;
                        text-align: left;
                        color: $gray2;
                    }
        
                    & input{
                        border: solid 1px $gray4;
                        border-radius: .2rem;
                        width: 100%;
                        display: block;
                        padding: .7rem 1rem;
                    }

                    & button{
                        width: 100%;
                        border: none;
                        border-radius: 2rem;
                        background-color: #25d366;
                        color: white;
                        font-size: 1rem;
                        font-weight: 600;
                        padding: .5rem 1rem;
                        cursor: pointer;
                        & i{
                            color: white;
                            font-size: 1.5rem;
                            font-weight: 400;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1023px){
    .imo_telefones_btn{
        position: fixed !important;
        bottom: 1rem !important;
        right: 1rem !important;
        z-index: 999;
           
        & #whatsapp_lista_telefones{
            padding: .5rem;
            position: fixed;
            bottom: 5rem;
            right: 2rem;

            & .corretor_btn_wpp{
                width: 290px;
                max-width: 90vw;

                & .corretor_img{
                    & .img{
                        width: calc(350px / 5.5);
                        height: calc(350px / 5.5);
    
                        & img{
                            width: calc(350px / 5.5);
                            height: calc(350px / 5.5);
                        }
                    }
                }
            }
        }
    }

    .pai_section_dialog{
        position: fixed !important;
        bottom: 2.5rem !important;
        right: 2.5rem !important;
        z-index: 9997;
        
        & .section_dialog{
            display: block;
            width: 80vw;
            padding: 1rem 2rem;
            border-radius: .5rem;
            background-color: white;
            position: relative;
            border: solid 2px $gray4;
            box-shadow: 0 0 20px 2px rgba(black, .2);
            & .btn_fechar_form_zap{
                position: absolute;
                top: -1rem;
                right: -1rem;
                background-color: #25d366;
                border-radius: 50%;
                padding: .5rem;
                border: solid 3px white;
                transition: all .15s;
                height: 2.5rem;
                width: 2.5rem;
                cursor: pointer;
                & i{
                    color: white;
                    text-align: center;
                    padding: 0 0 0 3px;
                }
                &:hover{
                    background-color: white;
                    border: solid 3px #25d366;
                    & i{
                        color: #25d366;
                    }
                }
            }
    
            & .dialog_wpp{
                display: block;
                width: 100%;
                padding: 0;
                & h2{
                    text-align: center;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: $colorTerciary;
                }
                & .under_p{
                    text-align: center;
                    width: 4rem;
                    height: 2px;
                    margin: auto;
                    background-color: #25d366;
                }
                & .inputs_wpp{
                    display: block;
                    width: 100%;
                    & .inputs_dados{
                        width: 100%;
                        display: block;
                        margin: 0 0 1rem 0;
                        padding: 0;
                        
                        & p{
                            margin: 0;
                            line-height: 1.5rem;
                            font-size: 1rem;
                            font-weight: 400;
                            text-align: left;
                            color: $gray2;
                        }
            
                        & input{
                            border: solid 1px $gray4;
                            border-radius: .2rem;
                            width: 100%;
                            display: block;
                            padding: .7rem .5rem;
                        }
            
                    }
                    & .input_msg{
                        width: 100%;
                        display: block;
                        margin: 0;
                        padding:0;
                        
                        & p{
                            margin: 0;
                            line-height: 1.5rem;
                            font-size: 1rem;
                            font-weight: 400;
                            text-align: left;
                            color: $gray2;
                        }
            
                        & textarea{
                            border: solid 1px $gray4;
                            border-radius: .2rem;
                            width: 100%;
                            height: 120px;
                            display: block;
                            padding: .7rem 1rem;
                        }
                    }
                }
            }
        }
    }
}