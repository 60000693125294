
#render_no_mapa{
	display: block;
	width: 100%;
	background-color: $colorPrimary;
}
#render_nomapa{
	display: block;
	width: 100%;
	background-color: #EEEEEE;
	height: 30vh;
	background-image: url('/jnh/map/padrao.jpg');
	background-size: cover;
	user-select: none;
	background-repeat: no-repeat;
	background-position: center;
}
.mapa_loading{
	display: flex;
	flex-direction: column;
	height: calc(100vh - 185px);
	align-items: center;
	justify-content: center;
	color: #FFF;
	background-image: url('/jnh/map/padrao.jpg');
	background-size: cover;
	user-select: none;
	background-repeat: no-repeat;
	background-position: center;
}

#render_nomapa .mapa_loading{
	color: inherit !important;
}