.title_condomi{
	text-align: center;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: white;
	}
	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray3;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.section_lancamentos{
	.title_condomi{
		h2{
			color: $colorTerciary !important;
		}
	}
	.miniatura-imovel{
		max-width: 350px;
	}

	.btn_ver_mais{
		p{
			color: $colorTerciary !important;
		}
	}
}
.section_servicos{
	width: 100%;
	display: block;
	background-color: $colorTerciary;
	text-align: center;

	& .margin_pc{
		margin: 0 4rem;
	}

	& a{
		width: 25%;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		position: relative;

		& .bloco_servico {
			display: block;
			position: relative;
			width: 100%;
			background-color: white;
			border: solid 1px darken($gray5, 3);
			border-radius: .2rem;
			padding: 1rem;
			z-index: 0;
			overflow: hidden;
	
			& .img{
				width: calc(512px / 11);
				height: calc(512px / 11);

				& img{
					transition: all .2s ease-in-out;
					text-align: center;
					width: calc(512px / 11);
					height: calc(512px / 11);
				}
			}

			& h2{
				color: $colorTerciary;
				font-size: 1.1rem;
				font-weight: 600;
				transition: all .3s;
				text-align: center;
			}

			& p{
				padding: 0;
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				transition: all .3s;
				text-align: center;
			}
		
			&::before {
				content: "";
				position: absolute;
				z-index: -1;
				top: -150px;
				right: -40px;
				background: $gray5;
				height: 32px;
				width: 32px;
				border-radius: 32px;
				transform: scale(1);
				transform-origin: 50% 50%;
				transition: transform 0.3s ease-out;
			}
		}

		&:hover{
			& .bloco_servico{
				& .img{
					& img{
						transform: scale(1.1);
					}
				}

				&::before {
					transform: scale(30);
				}
			}
		}
	}
}

#ux_to_top{
	position: relative;
    top: -80px;
    left: -33%;
    z-index: 999999;
}

.btn_cleanfilter{
	border-radius: .1rem !important;
	border: solid 1px $colorTerciary!important;
}

.btn_cleanfilter2{
	border-radius: .1rem !important;
	border: none;
}

.box_header{
	border-bottom: solid 2px $gray5;
	z-index: 999;
	position: relative;
}

.botao_voltar{
	background-color: $colorPrimary;
	border: none;
	border-radius: .2rem;
	padding: .3rem 1rem;
	color: white !important;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.section_servicos{
		width: 100%;
		display: block;
		background-color: $colorTerciary;
		text-align: center;
	
		& .margin_pc{
			margin: 0 4rem;
		}
	
		& a{
			width: 28%;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			position: relative;
		}
	}
}

@media all and (max-width: 1023px){

	.title_condomi{
		text-align: center;
		& h2{
			font-size: 1.7rem;
		}
		
		& p{
			font-size: 1rem;
		}

		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	#ux_to_top{
		left: 17%;
		top: -60px;
	}

	.section_servicos{
		
		& .margin_pc{
			margin: 0;
		}

		& a{
			width: 100%;
			& .bloco_servico {
				& .img{
					width: calc(512px / 13);
					height: calc(512px / 13);
	
					& img{
						width: calc(512px / 13);
						height: calc(512px / 13);
					}
				}
			}
		}
	}
}