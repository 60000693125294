.section_indices{
	display: block;
	width: 100%;
	background-color: $colorTerciary;
	border-top: solid 3px $colorPrimary;

	& .title_condomi{
		display: block;
		width: 100%;
		
		& h2{
			font-size: 1.5rem;
			font-weight: 500;
			color: white;
		}
	}
}

.footer1{
	border-top: solid 3px $colorPrimary;
	width: 100%;
	display: block;
	background-color: $colorTerciary;

	& .upfooter{
		background-color: white;
		padding: 1rem .5rem;
		width: 100%;
		display: block;

		& .logo_footer {
			max-width: 250px;
			margin: 0;

			& img {
				width: 100%;
			}
		}


		& .contatofooter{
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			& a{
				padding: 0 1rem;
				display: block;
				border-right: solid 2px transparent;
				transition: all .15s;
				border-radius: .1rem;
				& p{
					display: inline-block;
					vertical-align: middle;
					font-weight: 400;
					color: $colorTerciary;
					font-size: .9rem;
				}
				& i{
					font-size: 1.2rem;
					color: $colorPrimary;
					vertical-align: middle;
					display: inline-block;
				}
				&:hover{
					border-right: solid 2px $colorTerciary;
					padding-right: 1.5rem;
				}
			}

			& .tel_footer{
				display: inline-block;
				vertical-align: middle;
				padding: 0 1rem;
				font-weight: 400;
				color: $colorTerciary;
				font-size: .9rem;
				& i{
					font-weight: 500;
					color: $colorPrimary;
					vertical-align: middle;
					display: inline-block;
				}
			}

			& .rs_footer{
				display: block;
				width: 100%;
				& a{
					margin: 0 .5rem;
					display: inline-block;
					vertical-align: middle;
					border-right: none;
					padding: 0;
					
					& p{
						border: solid 1px transparent;
						border-radius: .1rem;
						transition: all .2s;
						& i{
							color: $colorPrimary;
							padding: .5rem;
							transition: all .2s;
						}
					}

					&:hover{
						& p{
							border: solid 1px $colorTerciary;
							background-color: $colorTerciary;
						}
						border-right: none;
						padding-right: none;
					}
				}
			}
		}
	}

	& .btn_footer{
		& a{
			border-left: solid 2px transparent;
			border-radius: 0 0 0 .5rem;
			transition: all .25s;
			&:hover{
				padding-left: .8rem;
				border-left: solid 2px white;
			}
		}
	}

	& .section_atendimento_footer{
		display: block;
		width: 100%;
		& h3{
			text-align: left;
			font-size: 1rem;
			font-weight: 600;
			color: white;
			padding: .5rem 0;
			user-select: none;
		}
		& .horarios{
			display: block;
			width: 100%;
			& p{
				color: white;
				font-size: .8rem;
				font-weight: 600;
				user-select: none;
				margin: 0;
				padding: 0;
			}

			& .p2{
				user-select: none;
				margin: 0;
				padding: 0;
				text-align: left;
				color: white;
				font-size: .8rem;
				font-weight: 400;
				line-height: 1.7rem;
			}
		}
	}

	& .section_imoveis_footer{
		display: block;
		width: 100%;
		& h3{
			text-align: left;
			font-size: 1rem;
			font-weight: 600;
			color: white;
			padding: .5rem 0;
			user-select: none;
		}

		& .imoveis_itens{
			display: inline-block;
			vertical-align: top;
			width: 100%;
			padding: 0 1rem;
			& a {
				width: fit-content;
				display: block;
				& p{
					color: white;
					font-size: .9rem;
					line-height: 1.7rem;
					font-weight: 400;
					&:hover{
						color: $gray4;
					}
				}
			}
		}

		& .imoveis_itens2{
			display: inline-block;
			vertical-align: top;
			width: 100%;
			padding: 0 1rem;
			& a {
				width: fit-content;
				display: block;
				& p{
					color: white;
					font-size: .9rem;
					line-height: 1.7rem;
					font-weight: 400;
					&:hover{
						color: $gray4;
					}
				}
			}
		}

	}

	& .section_corretores_footer{
		display: block;
		width: 100%;

		& h3{
			text-align: left;
			font-size: 1rem;
			font-weight: 600;
			color: white;
			padding: .5rem 0;
			user-select: none;
		}

		& .corretor{
			display: block;
			width: 100%;

			& .itens_tarja{
				width: 100%;
				display: block;

				& a{
					display: block;
					width: 100%;
					& p{
						line-height: 1.7rem;
						color: white;
						font-weight: 400;
						font-size: .8rem;
						margin: 0;
						padding: 0;
						&:hover{
							color: $gray4;
						}
					}
				}

				& p{
					line-height: 1.7rem;
					color: white;
					font-weight: 400;
					font-size: .8rem;
					margin: 0;
					padding: 0;
				}
				& .nome{
					color: white;
					font-weight: 600;
					font-size: .9rem;
					margin: 0;
					padding: 0;
				}

				& .redessociais{
					display: block;
					width: 100%;
					padding: .2rem 0;
					& a{
						display: inline-block;
						vertical-align: middle;
						width: 15%;

						& p{
							line-height: 1.7rem;
							margin: 0;
							padding: 0;
							& i{
								font-size: 1rem;
							}
						}
					}
				}
			}
		}
	}

	& .section_endereco_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: 1.1rem;
			font-weight: 700;
			color: white;
			padding: 1rem 0 0 0;
			user-select: none;
		}

		& .endereco_itens{
			display: block;
			width: 100%;
			& p{
				color: white;
				font-size: .9rem;
				line-height: 2rem;
				font-weight: 400;
				user-select: none;
				padding-right: 3rem;
			}
		}
	}

	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& p{
			text-align: left;
			color: white !important;
			font-size: .9rem;
			font-weight: 400;
			& strong{
				color: white;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;

		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 50%;

			& p{
				text-align: right;
				color: white;
				font-size: .9rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 25%;

			& .logo{
				width: calc(300px / 4);
				height: calc(300px / 4);

				& img{
					width: calc(300px / 4);
					height: calc(300px / 4);
				}
			}

			& .logo2{
				width: calc(500px / 4);
				height: calc(208px / 4);

				& img{
					width: calc(500px / 4);
					height: calc(208px / 4);
				}
			}
		}
	}
}
.line-hgt{
	line-height: 1.5rem !important;
	& a {
		line-height: 1.5rem !important;
	}
}
.footer-content a, .footer-content a:visited{
	color: #FFF;
	font-weight: normal;
	display: inline-block;
}
.footer-content a:hover, .footer-content a:active{
	text-decoration: underline;
}

.medium, .medium:hover{
	font-size: 1.15em;
}

.small{
	font-size: 0.9em;
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}

@media all and (max-width: 1023px){

	.footer1{
		& .upfooter{

			& .logo_footer {
				max-width: 250px;
				margin: 0 auto;
	
				& img {
					width: 100%;
				}
			}

			& .contatofooter{
				& a{
					padding: 0;
					text-align: left !important;

					& p{
						display: inline-block;
						vertical-align: middle;
						font-weight: 400;
						color: $colorTerciary;
						font-size: .9rem;
					}
					& i{
						vertical-align: middle;
						display: inline-block;
					}

					&:hover{
						border-color: transparent;
						padding-right: 0;
					}
				}
			}
		}

		& .section_imoveis_footer{
			display: block;
			width: 100%;
			& h3{
				text-align: left;
				font-size: 1.1rem;
				font-weight: 700;
				color: white;
				padding: 1rem 0;
				user-select: none;
			}
			& .imoveis_itens{
				display: block;
				width: 100%;
				padding: 0;
				& p{
					color: white;
					font-size: 1rem;
					line-height: 2rem;
					font-weight: 400;
					&:hover{
						color: $gray4;
					}
				}
			}
			& .imoveis_itens2{
				display: block;
				width: 100%;
				padding: 0;
				& p{
					color: white;
					font-size: 1rem;
					line-height: 2rem;
					font-weight: 400;
					&:hover{
						color: $gray4;
					}
				}
			}
		}
		
		& .copyright_footer{
			& p{
				text-align: center;
				color: $gray3;
				font-size: .9rem;
				font-weight: 500;
			}
		}

		& .devby{
			display: block;
			width: 100%;
			margin-bottom: 5rem;

			& .developed{
				width: 100%;
				display: block;
				& p{
					text-align: center;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 50%;

				& .logo{
					width: calc(300px / 4);
					height: calc(300px / 4);
	
					& img{
						width: calc(300px / 4);
						height: calc(300px / 4);
					}
				}
	
				& .logo2{
					width: calc(500px / 4);
					height: calc(208px / 4);
	
					& img{
						width: calc(500px / 4);
						height: calc(208px / 4);
					}
				}
			}
		}
	}
}