.container_miniaturas {
	display: grid;
	grid-template-columns: repeat(4,1fr);
	gap: 1.5rem;
}

.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	width: 100%;

	& .miniatura-imovel-img{
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 90%;
		background-color: $gray5;
		position: relative;
		user-select: none;
		border: solid 1px lighten($gray4, 10);
		border-bottom: none;
		transition: all .2s ease-in-out;

		& img{
			display: block;
			width: 420px;
			height: 420px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}

		.fade{
			background-color: rgba(black, .3);
			display: block;
			width: 100%;
			height: 100%;
			z-index: 99;
			top: 0%;
			left: 0%;
			position: absolute;
		
			& .miniatura-imv-status_va{
				top: 50%;
				left: 50%;
				display: block;
				position: absolute;
				background-color: $colorPrimary;
				padding: 1rem 1.5rem;
				color: white;
				border-radius: .2rem;
				font-weight: 600;
				border-color:rgba(#ffffff, .5);
				font-size: 2rem;
				@include border-box;
				transform: translate(-50%, -50%);
			}
		}

		& .miniatura-imv-status{
			position: absolute;
			top: 1rem;
			left: 0;
			
			& p{
				background-color: white;
				border-radius: .2rem 0 0 .2rem;
				font-size: .8rem;
				font-weight: 600;
				line-height: normal;
				text-align: center;
				color: $gray1;
				padding: .2rem .5rem;
				margin: 0
			}
		}

		& .miniatura-cod{
			position: absolute;
			top: 1rem;
			right: 0;
			& p{
				background-color: white;
				border-radius: .2rem 0 0 .2rem;
				font-size: .8rem;
				font-weight: 600;
				line-height: normal;
				text-align: center;
				color: $gray1;
				padding: .2rem .5rem;
				margin: 0
			}
		}
	}

	& .miniatura-imovel-detalhe{
		display: block;
		border: solid 1px lighten($gray4, 10);
		border-top: none;
		border-radius: 0 0 .2rem .2rem;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;
		transition: all .2s ease-in-out;

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& .miniatura-acao-tipo{
				display: inline-block;
				vertical-align: middle;
				width: 100%;

				& p{
					font-size: 1rem;
					font-weight: 600;
					color: $gray1;
					margin: 0;
				}
			}

			& p{
				text-transform: initial;
				font-size: .85rem;
				font-weight: 400;
				color: $gray2;
			}
		}

		& .miniatura-imovel-btns-icons{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			height: 4rem;
			width: 100%;

			& ul{
				& li{
					font-size: .85rem;
					color: $gray2;
					font-weight: 400;

					& i{
						color: $colorPrimary;
						font-weight: 400;
						font-size: 1.1rem;
					}
				}
			}
		}
		
		& .miniatura-preco{
			display: block;
			width: 100%;

			& p{
				width: 100%;
				padding-top: 1rem;
				text-align: left;
				font-size: 1.2rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
			}
		}
	}

	&:hover{
		& .miniatura-imovel-img{
			border-color: darken($gray4, 5);
		}

		& .miniatura-imovel-detalhe{
			border-color: darken($gray4, 5);
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.container_miniaturas {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1.5rem 1fr;
	}
	
	.miniatura-imovel {
		& .miniatura-imovel-detalhe{
			& .miniatura-preco{
				& .aca_tip{
					margin-top: 1rem;
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.container_miniaturas {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.5rem;
	}
}

