.header1{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}
.header2{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}
	
.menuacao_btn{
	background-color: $colorTerciary !important;
	color: white !important;
}

.headerindex {
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 45vh;
	padding: 3.5rem 0;
	@include border-box;
	z-index: 2;
	/* background-image: url('/jnh/header/banner.jpg'); */
	background-position: center bottom 27%;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg_section_imoveis {
	display: block;
	width: 100%;
	background-color: $colorTerciary;
}

.headerinterno {
	height: 5rem;
	z-index: 1;
}

#sub_menu_vendas{
	width: 240px;
	max-height: 300px;
	overflow: auto;
	display: none;
	position: absolute;
	left: 50%;
	top: 2rem;
	background-color: $colorTerciary;
	box-shadow: 0 0 10px 1px rgba(black, .2);
	border-radius: .2rem;
	transform: translate(-50%);

	& .elementos_sub_menu{
		text-align: left;
		& p{
			font-size: 1.1rem !important;
			font-weight: 400;
			padding: 1.2rem 1.5rem;
			margin: 0;
			transition: all .2s;
			border-left: solid 3px transparent;
			background-color: transparent;
			&:hover{
				border-left: solid 3px $colorPrimary;
				padding-left: 2rem;
				background-color: darken($colorTerciary, 2);
			}
		}
		& .titletipo{
			color: white;
			font-weight: 600;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 600;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	padding: 0;
	background-color: $colorTerciary;
	padding-block: 1.5rem;

	& .superior {
		display: block;
		width: 100%;
		z-index: 3;
		display: flex;
		align-items: center;
		
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 11%;

			& .logo_header {
				display: block;
				max-width: 250px;

				& img {
					display: block;
					width: 100%;
				}
			}
		}
		.creci_header{
			margin: 0 !important;
			display: inline-block;
			text-align: center;
			flex: 1;
		}
		/* GRUPO HEADER */

		$largura_menu: 5;
		$diferenca: 20;

		& .sessions {
			display: inline-block;
			vertical-align: middle;
			& ul {
				display: flex;
				align-items: center;
				list-style: none;
				justify-content: flex-end;
				transition: all .25s ease;

				& .itens_header{
					padding: 0 20px;
					
					& a{
						justify-content: space-between;
						color: white;
						font-weight: 600;
						font-size: 1rem;
						transition: all .2s;
						&:hover{
							color: $gray5;
						}
					}
					
					& .itemsession{
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: 100%;
	
						& a{
							color: white;
							font-weight: 600;
							font-size: 1rem;
							padding: 1rem 0;
							transition: all .2s;
							
							& i{
								font-size: .7rem;
								color: $colorPrimary;
							}
						}

	
						&:hover{
							#sub_menu_vendas{
								display: initial;
							}

							& a{
								color: $gray5;
								& i{
									color: $colorPrimary;
								}
							}
						}
						
					}
				}
			}
		}

		& .menu {
			text-align: right;
			display: inline-block;
			width: 5%;
			vertical-align: middle;
		}
	}
}

.header-mob{
	background-color: $colorTerciary;

	& .log{
		display: inline-block;
		vertical-align: middle;
		width: 80%;
		& .img{
			display: block;
			margin: 0;
			max-width: 150px;

			& img {
				width: 100%;
				display: block;
			}
		}
	}
	
	& .btn-men{
		width: 20%;
		text-align: right;
		display: inline-block;
		vertical-align: middle;
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.headerindex {
		width: 100%;
		height: 55vh;
		background-position: center bottom 38%;
	}

	.headerpc{
		& .superior {
			& .logo {
				width: 15%;

				& .logo_header {
					display: block;
					max-width: 200px;

					& img {
						display: block;
						width: 100%;
					}
				}
			}

			& .sessions {
				display: inline-block;
				width: 80%;
				vertical-align: middle;
			}
		}
	}
}

@media all and (max-width: 1023px){
	
	.headerindex{
		background-color: white;
		margin-bottom: 0;
		padding: 4rem 1.5rem;
		height: inherit;
		display: block;
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}
